<template>
  <div class="content">
		<!-- <h1>{{title}}</h1> -->
		<div v-html="content" class="html-box"></div>
	</div>
</template>
<script>
import { getPolicy } from '@/request/api/user';
export default {
	name: 'Policy',
	data () {
		return {
			id: '',
			content: '',
			title: ''
		};
	},
	created () {
		this.id = this.$route.query.id;
		this.getData();
	},
	methods: {
		getData () {
			getPolicy({ policyId: this.id }).then(data => {
				if (data && data === 'retry') {
					this.getData();
				} else if (data) {
					document.title = data.title;
					this.title = data.title;
					this.content = data.content;
				}
			});
		}
	}
};
</script>
<style lang="css" scoped>
.content {
	padding: 16px 0;
}
.content > h1 {
	text-align: center;
	font-size: 16px;
}
.html-box {
	padding: 16px;
	font-size: 15px !important;
}
.html-box >>> img {
	max-width: 100% !important;
	height: auto;
	display: block;
	margin: 0 auto;
}
.html-box >>> p {
	max-width: 100% !important;
	word-break: break-all;
	font-size: 15px !important;
}
.html-box >>> span {
	max-width: 100% !important;
	word-break: break-all;
	font-size: 15px !important;
}
</style>